body{
  font-family: 'Roboto', sans-serif;
}

@media (min-width: 1024px) {
  /* Styles for desktop browsers */
  /* Override default styles to mimic mobile view */
  body{
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;;
  

  }

  body #root{
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    border-radius: 10px;
    width: 360px;
    height: 840px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-tel-input .form-control{
  width: 100%!important;
}

div[class*="SnackbarItem-variantSuccess-"] {
  color: #fff !important;
  background-color: #31aba9 !important;
}

/* poper help tooltip pointer */
.triangle-code{
  height : 0;
  width : 0;
  border-top : 10px solid #31ABA9;
  border-right : 8px solid transparent;
  border-left : 8px solid transparent;
  margin: auto;
 }

/* carousel images states */
 .carousel .control-next.control-arrow:before{
  border-left: 8px solid #31ABA9!important;
 }

 .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #31ABA9!important;
}

.carousel .slide {
background-color: transparent!important;
}